import React from "react";
import {Flex, theme} from "antd";


const {useToken} = theme;


const StatsInline = ({title, value, id}) => {
    const {token} = useToken();

    return <Flex
        gap={"small"}
        align={"baseline"}
        style={{color: token.colorStats, width: "fit-content"}}
        id={id}
    >
        <span style={{fontSize: token.fontSizeHeading3}}>
            {value}
        </span>
        {title}
    </Flex>
}


export default StatsInline;
