import React, {useEffect, useState} from "react";
import {
    Button,
    Card,
    Col,
    Divider,
    Flex,
    Form,
    InputNumber,
    Row,
    Spin,
    Typography,
    theme
} from "antd";
import {
    ClockCircleFilled, FundFilled,
    ReloadOutlined
} from "@ant-design/icons";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import RetirementGraph, {
    retirementAmountSuccessProbability
} from "@holistico/proposal/view/retirement_map/RetirementGraph";
import NestedCard from "@components/retail/cards/NestedCard";
import StatsNonBordered from "@components/retail/cards/StatsNonBordered";
import CardHeader from "@components/retail/cards/CardHeader";
import {useAuth} from "@hooks/AuthHook";
import LockTooltip, {
    LockPlaceholder
} from "@components/retail/cards/LockTooltip";
import TourButton from "@components/retail/cards/TourButton";


const {useToken} = theme;


export const getRetirementInitialValues = (mkval) => {
    return {
        'life_expectancy': 100,
        'inflation_rate': 2.3,
        'monthly_savings': ValueFormatter.round(0.1 * (mkval ?? 0) / 12, -3),
        'monthly_withdrawals': ValueFormatter.round(0.2 * (mkval ?? 0) / 12, -3),
        'current_fee': 0.5,
        'proposed_fee': 0.5,
    }
}


export const RetirementOptions = ({setLoading, showProposed = false, id}) => {
    const [form] = Form.useForm();
    const birthYear = Form.useWatch('birth_year', form);
    const {proposal, updateQuestionnaire} = useHolisticoProposalContext()
    const currentYear = new Date().getFullYear();
    const mkval = proposal?.p_bucket_cur?.calculated_values?.market_value;
    const initialValues = getRetirementInitialValues(mkval);
    const portfolios = proposal?.current_portfolios;
    const {sendGAEvent} = useAuth();

    useEffect(() => {
        form.setFieldsValue(proposal?.questionnaire);
    }, [proposal])

    useEffect(() => {
        if (!form.getFieldValue('retirement_year') && birthYear > 1900 && birthYear < 2100) {
            form.setFieldValue('retirement_year', birthYear + 65);
        }
    }, [birthYear])

    const onFinish = (values) => {
        setLoading(true);
        updateQuestionnaire(values, false);
    };

    return <Form
        form={form}
        layout="horizontal"
        labelCol={{span: 16}}
        wrapperCol={{span: 8}}
        requiredMark={false}
        onFinish={onFinish}
        initialValues={initialValues}
        onKeyDown={(e) => e.key === "Enter" && e.preventDefault()}
    >
        <Flex justify={"center"} id={id}>
            <Row gutter={[16, 0]} style={{maxWidth: 800}}>
                <Col span={12}>
                    <Form.Item
                        label="Birth year"
                        name="birth_year"
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Retirement year"
                        name="retirement_year"
                        rules={[
                            {
                                required: true,
                                message: '* required'
                            }
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Life expectancy"
                        name="life_expectancy"
                        rules={[
                            {
                                required: true,
                                message: '* required'
                            },
                            ({getFieldValue}) => ({
                                validator(_, value) {
                                    let birth_year = getFieldValue('birth_year')
                                    if (!value || !birth_year || value + birth_year > currentYear) {
                                        return Promise.resolve();
                                    }
                                    return Promise.reject(new Error('* must be greater than current year'));
                                },
                            }),
                        ]}
                    >
                        <InputNumber/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Inflation rate, %"
                        name="inflation_rate"
                        tooltip={"Expected inflation rate in the long term"}
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber step={0.1}/>
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Monthly savings"
                        name="monthly_savings"
                        tooltip={"Monthly savings before retirement"}
                        rules={[{required: true, message: '* required'}]}
                    >
                        <InputNumber
                            step={1000}
                            formatter={(value) => ValueFormatter.currencyCustom(value, "")}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label="Monthly withdrawals"
                        name="monthly_withdrawals"
                        tooltip={"Monthly withdrawals after retirement"}
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber
                            step={1000}
                            formatter={(value) => ValueFormatter.currencyCustom(value, "")}
                        />
                    </Form.Item>
                </Col>
                <Col span={12}>
                    <Form.Item
                        label={showProposed ? "Current fee, %" : "Management fee, %"}
                        name="current_fee"
                        rules={[{
                            required: true,
                            message: '* required'
                        }]}
                    >
                        <InputNumber step={0.1}/>
                    </Form.Item>
                </Col>
                {showProposed
                    && <Col span={12}>
                        <Form.Item
                            label="Proposed fee, %"
                            name="proposed_fee"
                            rules={[{
                                required: true,
                                message: '* required'
                            }]}
                        >
                            <InputNumber step={0.1}/>
                        </Form.Item>
                    </Col>
                }
            </Row>
        </Flex>
        <Divider style={{margin: "0 0 16px 0"}}/>
        <Flex justify={"flex-end"}>
            <Button
                onClick={() => {
                    sendGAEvent({
                        category: 'Retirement Map',
                        action: 'Click',
                        label: 'Refresh chart'
                    });
                }}
                type={"link"}
                htmlType="submit"
                disabled={!portfolios || portfolios.length === 0}
                icon={<ReloadOutlined/>}
                id={`${id}-submit-button`}
            >
                Refresh chart
            </Button>
        </Flex>
    </Form>
}


export const RetirementMapChartWithStats = ({
                                                data,
                                                lifeExpectancy,
                                                investmentsAmount,
                                                divId,
                                                height = 250,
                                                showTips = false,
                                                noDataText = "No data",
                                                loading = false,
                                                showTitle = true,
                                                id
                                            }) => {
    const {token} = useToken();

    return <Spin size="large" spinning={loading}>
        {showTitle
            && <Typography.Title level={5}>
                Projected wealth
            </Typography.Title>
        }
        <RetirementGraph
            retirementData={data}
            lifeExpectancy={lifeExpectancy}
            investmentsAmount={investmentsAmount}
            divId={divId}
            height={height}
            showTips={showTips}
            noDataText={noDataText}
            id={id}
        />
        <Flex style={{marginTop: token.marginXL}}>
            <StatsNonBordered
                title={"Projected wealth at retirement"}
                value={
                    is_null(data?.projected_at_retirement)
                        ? "-"
                        : ValueFormatter.currency(data.projected_at_retirement)
                }
                id={`${id}-proj-wealth`}
            />
            <StatsNonBordered
                title={
                    " probability of running out of money when you turn "
                    + lifeExpectancy
                }
                value={retirementAmountSuccessProbability(100 - data?.survival_probability)}
                id={`${id}-out-prob`}
            />
        </Flex>
    </Spin>
}


const RetirementMapCardTour = ({}) => {
    const steps = [
        {
            title: 'Retirement map',
            description: "Retirement map helps to model the dynamics of your wealth",
            target: "retirement-map-card",
        },
        {
            title: 'Retirement map parameters',
            // description: "Fill the parameters ",
            target: "retirement-map-card-options",
        },
        {
            title: 'Refresh',
            description: "Click to apply parameters and refresh the chart",
            target: "retirement-map-card-options-submit-button",
        },
        {
            title: 'Wealth dynamics',
            description: <>
                Your wealth dynamics
                <br/> - Most probable case (center line)
                <br/> - Worst case (bottom line, the probability of falling
                below this line is 5%)
                <br/> - Best case (top line, the probability of being above
                this line is 5%)
            </>,
            target: "retirement-map-card-chart",
        },
        {
            title: 'Projected wealth at retirement',
            target: "retirement-map-card-chart-proj-wealth",
        },
        {
            title: 'Probability of running out of money',
            target: "retirement-map-card-chart-out-prob",
        },
    ];

    return <TourButton steps={steps}/>
}


const RetirementMapCard = ({}) => {
    const {isDataLocked} = useAuth();
    const {
        proposal,
        retirement,
        loadingRetirement,
        setLoadingRetirement
    } = useHolisticoProposalContext();
    const {token} = useToken();
    const {sendGAEvent} = useAuth();

    const locked = isDataLocked("#retirement-map");

    return <Card
        hoverable
        style={{
            background: locked ? "white" : token.colorBgGrey,
            borderColor: "rgb(220, 220, 220)",
            cursor: "default"
        }}
        id={"retirement-map-card"}
    >
        <NestedCard background={"white"}>
            <CardHeader title="Retirement map" icon={<ClockCircleFilled/>}
                        tour={<RetirementMapCardTour/>}/>
            {!retirement &&
                <Flex gap={"small"} align={"center"}>
                    <Typography.Text type={"secondary"}>
                        Fill the form above and click "Refresh chart" to get
                        retirement map
                    </Typography.Text>
                </Flex>
            }
            <Divider/>
            <LockTooltip label={"Retirement map"} blur locked={locked}>
                <RetirementOptions setLoading={setLoadingRetirement}
                                   id={"retirement-map-card-options"}/>
            </LockTooltip>
        </NestedCard>
        <LockTooltip label={"Retirement map"} blur locked={locked}>
            <RetirementMapChartWithStats
                loading={loadingRetirement}
                data={retirement}
                lifeExpectancy={proposal?.questionnaire?.life_expectancy}
                investmentsAmount={proposal?.p_bucket_cur?.calculated_values?.market_value}
                divId={"retirementGraphContainer"}
                height={250}
                id={"retirement-map-card-chart"}
            />
        </LockTooltip>
    </Card>
}


export default RetirementMapCard;
