/* eslint-disable react/prop-types */
import React, {useEffect, useRef, useState} from 'react';
import {useDebounce} from 'ahooks';
import {LoadingOutlined, SearchOutlined} from '@ant-design/icons';
import {
    Col,
    ConfigProvider,
    Divider,
    Dropdown,
    Flex,
    Input,
    message,
    Modal,
    Row,
    theme,
} from 'antd';
import {cancel, searchAssets} from "@API/asset";
import SecurityForm from '@root/components/portfolio/position/SecurityForm';
import "./NewPosition.less"


const {useToken} = theme;


const getMenuConfig = (assets, searchString, allowCreate) => {
    const items = [];
    const keys = [];
    const _assets = {};

    if (Object.keys(assets).length > 0) {
        if (assets.exact_match) {
            let key = "exact_match";
            keys.push(key);
            _assets[key] = assets.exact_match;
            items.push({
                key: key,
                label: (<AssetMenuLabel asset={assets.exact_match}/>)
            })
        }
        Object.keys(assets).filter(key => key !== "exact_match").map(securityType => {
            items.push({
                key: securityType,
                label: securityType,
                type: 'group',
                children: assets[securityType].map((asset, idx) => {
                    let key = `${securityType}.${idx}`;
                    keys.push(key);
                    _assets[key] = asset;
                    return {
                        key: key,
                        label: (<AssetMenuLabel asset={asset}/>)
                    }
                })
            })
        })
    } else {
        if (searchString !== "") {
            let key = "nothing_found";
            items.push({
                key: key,
                disabled: true,
                label: "Nothing found..."
            })
        }
        if (allowCreate && searchString !== "") {
            let key = "create_new_asset";
            keys.push(key);
            items.push({
                key: key,
                label: "+ Create new asset"
            })
        }
    }

    return {
        items: items,
        keys: keys,
        assets: _assets
    }
}


const getActiveKeyByIndex = (keys, idx) => {
    if (!keys || keys.length === 0) {
        return null;
    }
    const n = keys.length;
    const idxNorm = idx % n >= 0 ? idx % n : idx % n + n;
    return keys[idxNorm];
}


const AssetMenuLabel = ({asset}) => {
    return <Row>
        <Col span={4}>
            {asset?.ticker || asset?.cusip || asset?.gvkey || asset?.gvkeyx || "-"}
        </Col>
        <Col span={20}>
            {asset?.name || "No asset name"}
        </Col>
    </Row>
}


const AssetSearch = ({
                         initialValue = "",
                         onSelect = () => {
                         },
                         allowCreate = false,
                         tableView = false,
                         byTickerOnly = true,
                         placeholder = "Search asset...",
                         size = null,
                         icon = <SearchOutlined/>,
                         autofocus = true,
                         style = {},
                         id
                     }) => {
    const [inputValue, setInputValue] = useState(initialValue);
    const [searching, setSearching] = useState(false);
    const [menuConfig, setMenuConfig] = useState({});
    const [activeIdx, setActiveIdx] = useState(0);
    const [openMenu, setOpenMenu] = useState(false);
    const [openCreateNew, setOpenCreateNew] = useState(false);
    const inputValueDebounced = useDebounce(inputValue, {wait: 500});
    const {token} = useToken();
    const inputRef = useRef(null);

    useEffect(() => {
        search(inputValueDebounced);
    }, [inputValueDebounced])

    const search = (searchString) => {
        if (cancel) {
            cancel();
        }
        if (searchString !== "") {
            setSearching(true);
            searchAssets(searchString, byTickerOnly, (data, error) => {
                let assets = {};
                if (!error) {
                    assets = data;
                } else {
                    message.error("Something went wrong while searching the assets!");
                }
                setMenuConfig(getMenuConfig(assets, searchString, allowCreate));
                setActiveIdx(0);
                setSearching(false);
            })
        } else {
            setMenuConfig(getMenuConfig({}, searchString, allowCreate));
            setActiveIdx(0);
        }
    }

    const handleMenuClick = (key) => {
        inputRef.current.blur();
        if (key === "create_new_asset") {
            // Need time to hide menu
            setTimeout(() => setOpenCreateNew(true), 300);
        } else {
            setInputValue("");
            onSelect(menuConfig.assets[key]);
        }
    }

    const dropdownProps = {
        trigger: ["click"],
        menu: {
            items: menuConfig.items,
            selectedKeys: [getActiveKeyByIndex(menuConfig.keys, activeIdx)],
            onClick: (item) => handleMenuClick(item.key),
            // Prevent default onMouseDown to not destroy dropdown
            // before item is chosen
            onMouseDown: e => e.preventDefault(),
        },
        open: openMenu && menuConfig.items && menuConfig.items.length > 0,
    }

    const inputProps = {
        value: inputValue,
        prefix: tableView
            ? null
            : <span style={{marginRight: 8}}>
                {searching ? <LoadingOutlined/> : icon}
            </span>,
        placeholder: placeholder,
        autoFocus: autofocus,
        size: size,
        ref: inputRef,
        onChange: e => setInputValue(e.target.value),
        onKeyDown: (e) => {
            if (e.key === "ArrowDown") {
                e.preventDefault();
                setActiveIdx(prev => prev + 1);
            }
            if (e.key === "ArrowUp") {
                e.preventDefault();
                setActiveIdx(prev => prev - 1);
            }
            if (e.key === "Enter" && !searching && inputValue === inputValueDebounced) {
                inputRef.current.blur();
                handleMenuClick(getActiveKeyByIndex(menuConfig.keys, activeIdx));
            }
        },
        onFocus: () => setTimeout(() => setOpenMenu(true), 100),
        onBlur: () => setOpenMenu(false),
    }


    return <ConfigProvider
        theme={{
            components: {Dropdown: {borderRadiusLG: token.borderRadiusSM}},
            token: {lineWidthFocus: 1}
        }}
    >
        {tableView &&
            <Row type="flex"
                 justify="start"
                 align="middle"
                 gutter={[8, 0]}
                 className="portfolio-position-row"
                 id={id}
            >
                <Col span={1} style={{textAlign: "center"}}>
                    {searching ? <LoadingOutlined/> : icon}
                </Col>
                <Col span={23}>
                    <Dropdown {...dropdownProps}>
                        <Input {...inputProps} className={"input-addStock"}
                               style={style}/>
                    </Dropdown>
                </Col>
            </Row>
        }
        {!tableView &&
            <Flex id={id}>
                {/*<Flex justify={"center"} align={"center"} style={{minWidth: 32, maxWidth: 32}}>*/}
                {/*    {searching ? <LoadingOutlined/> : icon}*/}
                {/*</Flex>*/}
                <Dropdown {...dropdownProps}>
                    <Input {...inputProps} style={{
                        borderRadius: token.borderRadius,
                        fontSize: token.fontSize,
                        ...style
                    }}/>
                </Dropdown>
            </Flex>
        }
        <Modal
            title={"New asset"}
            footer={null}
            open={openCreateNew}
            onCancel={() => {
                setInputValue("");
                setMenuConfig(getMenuConfig({}, "", allowCreate));
                setOpenCreateNew(false);
            }}
            width={750}
            closable={false}
            maskClosable={true}
        >
            <Divider style={{margin: "0 0 16px 0"}}/>
            <SecurityForm
                handleSubmit={(asset) => {
                    setInputValue("");
                    setMenuConfig(getMenuConfig({}, "", allowCreate));
                    setOpenCreateNew(false);
                    onSelect(asset);
                    inputRef.current.focus();
                }}
                asset={{ticker: inputValue.toUpperCase()}}
                trigger={Math.random()}
            />
        </Modal>
    </ConfigProvider>

}

export default AssetSearch;
