import {
    List,
    Input,
    Button,
    Avatar,
    Typography,
    Flex, Card, theme, Menu, Image
} from 'antd';
import React, {useMemo, useRef, useState} from "react";
import {useAuth} from "@hooks/AuthHook";
import {SendOutlined, SettingOutlined, UserOutlined} from "@ant-design/icons";
import {highlight} from "@holistico/proposal/view/ai/textWorkers";
import {useAiChatContext} from "@hooks/AiChatContext";

import CardHeader from "@components/retail/cards/CardHeader";
import {useSize} from "ahooks";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import ConfigurationForm from "@holistico/proposal/view/ai/ConfigurationForm";
import {HoliAvatar} from "@components/retail/portfolio/AiChatPanel";
import {ChatMessageContent} from "@holistico/ai/chat/AiChatMessage";


const {useToken} = theme;


const ChatMessage = ({role, content, user, style={}}) => {
    const {token} = useToken();

    return <List.Item style={{
        padding: token.marginLG,
        marginLeft: -token.marginLG,
        marginRight: -token.marginLG,
        whiteSpace: "pre-line",
        ...(role === "user" ? {
            background: token.colorBgGreyDark
        } : {

        }),
        ...style
    }}>
        <Flex vertical>
            <Flex align={"center"} gap={"middle"}>
                {role === 'assistant' || role === 'assistant-thinking'
                    ? <HoliAvatar
                        // icon={
                            // <Typography.Text strong style={{color: "white"}}>
                            //     H
                            // </Typography.Text>
                            // <Image
                            //     src={"/img/holi_logo_white.png"}
                            //     preview={false}
                            //     // height={16}
                            //     width={10}
                            // />
                        // }
                    />
                    : <Avatar src={user?.picture}/>
                }
                <Typography.Text style={{fontWeight: "800"}}>
                    {role === 'assistant' || role === 'assistant-thinking'
                        ? 'Holi'
                        : user.meta.full_name
                    }
                </Typography.Text>
            </Flex>
            <div style={{marginLeft: 48}}>
                <ChatMessageContent role={role} content={content}/>
            </div>
        </Flex>

    </List.Item>
}


const ClientChat = ({showConfigButton=false, style={}}) => {
    const {
        messageList,
        sendMessage,
        waitingForResponse,
        predefinedMessages,
        getPredefinedMessageByKey
    } = useAiChatContext();
    const auth = useAuth();
    const [currentMessage, setCurrentMessage] = useState('');
    const [openDrawer, setOpenDrawer] = useState(false);
    const divRef = useRef(null);
    const searchInputRef = useRef(null);
    const size = useSize(divRef);
    const {token} = useToken();

    const messagesToShow = useMemo(() => {
        if (!messageList || messageList.length === 0) {
            return []
        }

        let swapped = [];
        let buffer = [];
        messageList.map(msg => {
            if (msg.role === 'user') {
                swapped = [...buffer, ...swapped];
                buffer = [msg];
            } else {
                buffer.push(msg)
            }
        })
        swapped = [...buffer, ...swapped];

        return swapped;
    }, [messageList]);


    const loadPredefinedMessage = (key) => {
        let msg = getPredefinedMessageByKey(key);
        setCurrentMessage(msg);
        searchInputRef.current.focus();
    }

    return <Card hoverable style={{
        cursor: "default",
        borderColor: "rgb(220, 220, 220)",
        overflow: "hidden",
        minHeight: 500,
        ...style
    }}>
        <CardHeader
            title={"Chat"}
            controls={showConfigButton &&
                <Button
                    type={"text"}
                    icon={<SettingOutlined />}
                    onClick={() => setOpenDrawer(true)}
                />
            }
        />
        <Flex vertical>
            <Input.Search
                placeholder={'Type your question here'}
                autoFocus={true}
                ref={searchInputRef}
                size={'large'}
                loading={waitingForResponse}
                disabled={waitingForResponse}
                value={currentMessage}
                enterButton={
                    <Button
                        icon={<SendOutlined/>}
                        shape="round"
                        type="primary"
                        size="middle"
                        loading={waitingForResponse}
                    />
                }
                onSearch={sendMessage}
                onChange={(e) => setCurrentMessage(e.target.value)}
            />
            <Flex wrap={"wrap"} style={{marginLeft: -token.margin}}>
                {predefinedMessages.map((obj => {
                    return <Button
                        key={obj.key}
                        type={"link"}
                        // size={"small"}
                        onClick={() => loadPredefinedMessage(obj.key)}
                        style={{
                            textTransform: "lowercase",
                            padding: 0,
                            marginLeft: token.margin
                        }}
                    >
                        {obj.label}
                    </Button>
                }))}
            </Flex>
        </Flex>
        <div ref={divRef} style={{marginTop: token.margin}}>
            <List
                gutter={12}
                dataSource={messagesToShow}
                size={'small'}
                renderItem={(item, idx) => {
                    return <ChatMessage
                        key={idx}
                        content={item.content}
                        role={item.role}
                        user={auth.user}
                        small={size?.width < 800}
                        style={
                            idx < messagesToShow.length - 1 ? {
                                border: "none"
                            } : {
                                borderTop: `1px solid rgb(220, 220, 220)`,
                            }
                        }
                    />
                }}
            />
        </div>
        <MobileDrawer
            open={openDrawer}
            onClose={() => setOpenDrawer(false)}
            width={400}
        >
            <ConfigurationForm/>
        </MobileDrawer>
    </Card>

}

export default ClientChat;
