import React, {useEffect, useMemo, useRef, useState} from "react";
import {
    Button,
    theme, Tooltip, Tour,
} from "antd";
import {
    BulbOutlined,
} from "@ant-design/icons";


const {useToken} = theme;


const TourButton = ({steps}) => {
    const [open, setOpen] = useState(false);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    const existingSteps = useMemo(() => {
        const _steps = [];
        steps.map(step => {
            if(document.getElementById(step.target)) {
                _steps.push({...step, target: () => document.getElementById(step.target)});
            }
        })
        return _steps;
    }, [steps])

    return <>
        <Tooltip title={"Educational tour"}>
            <Button
                type={"text"}
                onClick={() => setOpen(true)}
                onMouseLeave={() => setHover(false)}
                onMouseEnter={() => setHover(true)}
                style={{
                    background: hover ? token.colorBgGreyDark : token.colorBgGrey,
                    borderRadius: 40
                }}
            >
                <BulbOutlined/>
            </Button>
        </Tooltip>
        <Tour
            type="primary"
            open={open}
            onClose={() => setOpen(false)}
            steps={existingSteps}
            gap={{radius: token.borderRadius, offset: token.marginXS}}
        />
    </>
}


export default TourButton;
