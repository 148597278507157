/* eslint-disable react/prop-types */
import React, {useEffect, useState} from 'react';
import {Pie, G2} from "@ant-design/charts";
import ChartStyles from "@global/ChartStyles";
import ValueFormatter from "@global/ValueFormatter";
import NoDataPlaceholder from "@holistico/aux/NoDataPlaceholder";
import {calcData} from "@holistico/chart/AssetClassPie";


const calcAlignment = (data) => {
    const align = {}
    const cumulativeSum = (sum => (item) => {
        if ((sum + sum + item.value) / 2 > 0.5) {
            align[item.label] = 'right'
        } else {
            align[item.label] = 'left'
        }
        sum += item.value;
        return sum;
    })(0)
    data.map(cumulativeSum)

    return align
}


const AssetClassPie = ({allocation, id}) => {
    const [config, setConfig] = useState(null);
    const G = G2.getEngine('canvas');

    useEffect(() => {
        if (!allocation || Object.keys(allocation).length === 0) {
            setConfig(null)
            return
        }

        const values = Object.values(allocation);
        const labels = Object.keys(allocation);
        const max_value = Math.max(...values);
        const max_label = labels[values.indexOf(max_value)];
        const [_data, _pure_data] = calcData(allocation)
        const _align = calcAlignment(_data)

        const _config = {
            data: _data,
            angleField: 'value',
            colorField: 'label',
            radius: 1,
            innerRadius: 0.8,
            autoFit: false,
            height: 200,
            legend: false,
            responsive: false,
            padding: [20, 20, 24, 20],
            color: (item) => {
                return ChartStyles.securityTypeColor(item.label);
            },
            pieStyle: (item) => {
                return {
                    stroke: allocation[item.label] >= 0 ? "white" : "red",
                    lineWidth: 2
                }
            },
            label: {
                type: 'spider',
                labelHeight: 40,
                formatter: (_data, mappingData) => {
                    const group = new G.Group({});
                    group.addShape({
                        type: 'text',
                        attrs: {
                            y: 8,
                            text: `● ${_data.label}`,
                            fontSize: 14,
                            fontWeight: 400,
                            fontFamily: 'Red Hat Display W',
                            fill: mappingData.color,
                            textAlign: _align[_data.label]
                        },
                    });
                    group.addShape({
                        type: 'text',
                        attrs: {
                            y: 25,
                            text: ValueFormatter.oneDigitPercent(allocation[_data.label]),
                            fontSize: 14,
                            fontWeight: 500,
                            fontFamily: 'Red Hat Display W',
                            fill: 'rgba(0, 0, 0, 0.65)',
                            textAlign: _align[_data.label]
                        },
                    });
                    return group;
                },
            },
            statistic: {
                title: {
                    formatter: () => ValueFormatter.softPercent(max_value),
                    style: {
                        fontSize: 16,
                        fontFamily: "Red Hat Display W",
                        fontWeight: 400,
                    },
                    offsetY: -10,

                },
                content: {
                    formatter: () => max_label,
                    style: {
                        fontSize: 16,
                        fontFamily: "Red Hat Display W",
                        fontWeight: 500,
                        height: "1.2em"
                    },
                    offsetY: -6,
                },
            },
            meta: {
                percent: {
                    formatter: (v) => {
                        return ValueFormatter.oneDigitPercent(v)
                    }
                }
            },
            tooltip: {
                formatter: (item) => {
                    let value = allocation[item.label];
                    if (!value) {
                        value = allocation[item.label.split(" ")[0]]
                    }
                    return {
                        name: item.label,
                        value: ValueFormatter.oneDigitPercent(value),
                    };
                },
            },
        };
        setConfig(_config)
    }, [allocation])


    return <div style={{minWidth: 445, maxWidth: 445}} className={"fade-in-500"} id={id}>
        {config
            ?
            <Pie {...config}/>
            :
            <NoDataPlaceholder description={"No asset class information"}/>
        }
    </div>
}


export default AssetClassPie;
