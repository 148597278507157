import React, {useState} from "react";
import {Col, Flex, Row, Grid, theme} from "antd";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import EnrollmentCard from "@components/retail/portfolio/EnrollmentCard";
import AccountsCard from "@components/retail/portfolio/AccountsCard";
import RiskProfileCard from "@components/retail/portfolio/RiskProfileCard";
import OverviewCard from "@components/retail/portfolio/OverviewCard";
import AnalyticsCard from "@components/retail/portfolio/AnalyticsCard";
import StressTestCard from "@components/retail/portfolio/StressTestCard";
import RetirementMapCard from "@components/retail/portfolio/RetirementMapCard";
import PositionsCard from "@components/retail/portfolio/PositionsCard";

const {useToken} = theme;
const {useBreakpoint} = Grid;


const MyPortfolio = ({}) => {
    const {proposal} = useHolisticoProposalContext();
    const screens = useBreakpoint();
     const {token} = useToken();

    const portfolios = proposal?.current_portfolios;

    return <>{proposal &&
        (portfolios && portfolios.length > 0
                ?
                <>
                    {screens.xl
                        ?
                        <Row gutter={[token.margin, token.margin]}>
                            <Col span={14}>
                                <Flex vertical gap={token.margin}>
                                    <AccountsCard/>
                                    <AnalyticsCard/>
                                    <PositionsCard/>
                                </Flex>
                            </Col>
                            <Col span={10}>
                                <Flex vertical gap={token.margin}>
                                    <RiskProfileCard/>
                                    <OverviewCard/>
                                    <StressTestCard/>
                                    <RetirementMapCard/>
                                </Flex>
                            </Col>
                        </Row>
                        :
                        <Row gutter={[token.margin, token.margin]}>
                            <Col span={24}>
                                <AccountsCard/>
                            </Col>
                            <Col span={24}>
                                <RiskProfileCard/>
                            </Col>
                            <Col span={24}>
                                <OverviewCard/>
                            </Col>
                            <Col span={24}>
                                <AnalyticsCard/>
                            </Col>
                            <Col span={24}>
                                <StressTestCard/>
                            </Col>
                            <Col span={24}>
                                <RetirementMapCard/>
                            </Col>
                            <Col span={24}>
                                <PositionsCard/>
                            </Col>
                        </Row>
                    }
                </>
                :
                <EnrollmentCard/>
        )
    }</>
}


export default MyPortfolio;
