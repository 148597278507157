import React, {useState} from "react";
import {useSet} from "ahooks";
import {
    Button,
    Card,
    ConfigProvider,
    Flex,
    List,
    Typography,
    Table,
    theme,
} from "antd";
import {
    AlertFilled,
    CheckCircleFilled,
    DownOutlined,
    ExclamationCircleFilled,
    UpOutlined,
} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import StatsInline from "@components/retail/cards/StatsInline";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {
    QuestionnaireDrawer,
    QuizDrawer
} from "@components/retail/portfolio/RiskProfileCard";
import CardHeader from "@components/retail/cards/CardHeader";
import NestedCard from "@components/retail/cards/NestedCard";
import {useAuth} from "@hooks/AuthHook";
import {useParams} from "react-router-dom";
import LockTooltip, {LockPlaceholder} from "@components/retail/cards/LockTooltip";
import TourButton from "@components/retail/cards/TourButton";


const {useToken} = theme;


const CHECKS_DISPLAY_SETTINGS = {
    risk_ability_not_set: {
        successTitle: "Risk ability questionnaire filled",
        warningTitle: "Risk ability unknown",
        warningDescription:
            "Risk ability score reflects your financial ability " +
            "to withstand declines in the portfolio value. " +
            "It helps to better understand if the portfolio meets your " +
            "goals and risk-taking ability."
    },
    high_risk_vs_ability: {
        successTitle: "Portfolio risk meets risk ability",
        warningTitle: "High portfolio risk compared to risk ability",
        warningDescription:
            "This warning may indicate that the portfolio " +
            "does not meet your financial ability to take risk.",
    },
    risk_tolerance_not_set: {
        successTitle: "Risk tolerance quiz passed",
        warningTitle: "Risk tolerance unknown",
        warningDescription:
            "Risk tolerance score measures your subconsciousness tolerance " +
            "to risk. " +
            "It helps to better understand if the portfolio meets your " +
            "goals and behavioral ability/desire to take risk.",
    },
    high_risk_vs_tolerance: {
        successTitle: "Portfolio risk meets risk tolerance",
        warningTitle: "High portfolio risk compared to risk tolerance",
        warningDescription:
            "This warning may indicate that the portfolio " +
            "does not meet your subconsciousness tolerance to risk",
    },
    bad_sector_diversification: {
        successTitle: "Good equity sector diversification",
        warningTitle: "Bad equity sector diversification",
        warningDescription:
            "This warning indicates that " +
            "the portfolio equity part is poorly diversified and " +
            "its performance highly depends on one or few sectors. ",
    },
    bad_fundamentals: {
        successTitle: "Good equity fundamentals",
        warningTitle: "Bad equity fundamentals",
        warningDescription:
            "This warning indicates that the " +
            "equity part has poor fundamental characteristics. ",
    },
    high_expense_ratio_funds: {
        successTitle: "No high expense ratio funds",
        warningTitle: "High expense ratio funds",
        warningDescription:
            "This warning indicates that the portfolio contains funds with " +
            "high expense ratio, which leads to additional costs.",
    },
    large_positions: {
        successTitle: "No too large positions",
        warningTitle: "Too large positions",
        warningDescription:
            "Large positions may indicate that the portfolio " +
            "is poorly diversified and its performance highly " +
            "depends on one (or few) assets.",
    },
    large_cash: {
        successTitle: "Not too much free cash",
        warningTitle: "Too much free cash",
        warningDescription:
            "High cash weight may indicate inefficient portfolio composition, " +
            "which leads to the loss of the risk-free income. " +
            "It also may indicate short positions, " +
            "which lead to high portfolio risk.",
    },
    short_positions: {
        successTitle: "No short positions",
        warningTitle: "Short positions",
        warningDescription:
            "Short positions increase portfolio risk. In the case " +
            "when the price of a short position rises, " +
            "losses are not bound.",
    },
    short_funds: {
        successTitle: "No short/inverse funds",
        warningTitle: "Short/inverse funds",
        warningDescription:
            "Short/inverse funds may increase portfolio risk. " +
            "In the case when the price of a short position rises, " +
            "losses are not bound.",
    },
    negative_cash: {
        successTitle: "No negative cash",
        warningTitle: "Negative cash weight",
        warningDescription:
            "Negative cash may indicate leveraged positions, " +
            "which may increase portfolio risk.",
    },
    leveraged_funds: {
        successTitle: "No leveraged funds",
        warningTitle: "Leveraged funds",
        warningDescription:
            "Leveraged funds may increase portfolio risk. Furthermore, " +
            "leveraged funds are frequently rebalanced, which comes " +
            "with additional costs.",
    },
};
const RISK_RELATED_CHECKS = [
    "bad_sector_diversification",
    "large_positions",
    "short_positions",
    "short_funds",
    "negative_cash",
    "leveraged_funds",
];
const DETAILS_TABLE_COLUMNS = [
    {
        title: 'Ticker',
        dataIndex: 'ticker',
        key: 'ticker',
    },
    {
        title: 'Name',
        dataIndex: 'name',
        key: 'name',
        width: "50%",
    },
    {
        title: 'Market Value',
        dataIndex: 'market_value',
        key: 'market_value',
        align: "right",
        render: (value) => ValueFormatter.currency(value)
    },
    {
        title: 'Weight',
        dataIndex: 'weight',
        key: 'weight',
        align: "right",
        render: (value) => ValueFormatter.oneDigitPercent(value)
    },
    {
        title: 'Expense Ratio',
        dataIndex: 'expense_ratio',
        key: 'expense_ratio',
        align: "right",
        render: (value) => ValueFormatter.oneDigitPercent(value / 100)
    },
    {
        title: 'Short/Inverse',
        dataIndex: 'short_fund',
        key: 'short_fund',
        render: (value) => value ? "Yes" : "No"
    },
    {
        title: 'Leveraged',
        dataIndex: 'leveraged_fund',
        key: 'leveraged_fund',
        render: (value) => value ? "Yes" : "No"
    },
    {
        title: 'SScore+',
        dataIndex: 'sscore_plus',
        key: 'sscore_plus',
        align: "right",
        render: (value) => ValueFormatter.round(value, 0)
    },
    {
        title: 'Sector',
        dataIndex: 'Sector',
        key: 'sector',
    },
    {
        title: 'Portfolio Weight',
        dataIndex: 'Portfolio weight, %',
        key: 'portfolio_weight',
        align: "right",
        render: (value) => ValueFormatter.oneDigitPercent(value / 100)
    },
    {
        title: 'Stock Market Weight',
        dataIndex: 'Benchmark weight, %',
        key: 'benchmark_weight',
        align: "right",
        render: (value) => ValueFormatter.oneDigitPercent(value / 100)
    },
    {
        title: 'Difference',
        dataIndex: 'Difference, p.p.',
        key: 'weight_difference',
        align: "right",
        render: (value) => ValueFormatter.oneDigitPercent(value / 100)
    },
]


const getRiskChecks = (proposal, portfolioChecks) => {
    const riskScore = proposal?.p_bucket_cur?.calculated_values?.risk?.risk_score;
    const riskAbility = proposal?.questionnaire?.risk_profile?.ability;
    const riskTolerance = proposal?.questionnaire?.risk_profile?.behavioral;

    const riskChecks = {};
    const details = Object.keys(portfolioChecks)
        .filter(key => RISK_RELATED_CHECKS.includes(key) && !portfolioChecks.passed)
        .map(key => portfolioChecks[key])
    if (is_null(riskAbility)) {
        riskChecks["risk_ability_not_set"] = {
            label: "Risk ability not set",
            passed: false,
            soft: true,
            details: details
        };
    } else {
        riskChecks["high_risk_vs_ability"] = {
            label: "High risk compared to risk ability",
            metric: {
                label: "Portfolio risk score",
                limit: riskAbility,
                value: riskScore,
                units: null,
            },
            passed: riskAbility && riskAbility > riskScore,
            details: details
        };
    }

    if (is_null(riskTolerance)) {
        riskChecks["risk_tolerance_not_set"] = {
            label: "Risk ability not set",
            passed: false,
            soft: true,
            details: details
        };
    } else {
        riskChecks["high_risk_vs_tolerance"] = {
            label: "High risk compared to risk tolerance",
            metric: {
                label: "Portfolio risk score",
                limit: riskTolerance,
                value: riskScore,
                units: null,
            },
            passed: riskTolerance && riskTolerance > riskScore,
            details: details
        };
    }

    return riskChecks;
}


export const getChecks = (proposal) => {
    const portfolioChecks = proposal?.p_bucket_cur?.calculated_values?.check_points ?? {};
    const riskChecks = getRiskChecks(proposal, portfolioChecks);
    const checks = {...riskChecks, ...portfolioChecks};
    const checksExtended = [];
    for (let key of Object.keys(CHECKS_DISPLAY_SETTINGS)) {
        if (checks.hasOwnProperty(key)) {
            checksExtended.push({
                key: key,
                ui: CHECKS_DISPLAY_SETTINGS[key],
                ...checks[key]
            })
        }
    }
    return checksExtended;
}


const DetailsTable = ({data, columns, rowKey="position_index"}) => {
    return <Table
        dataSource={data}
        pagination={false}
        size="small"
        rowKey={rowKey}
        columns={DETAILS_TABLE_COLUMNS.filter(c => columns.includes(c.dataIndex))}
    />
}


const CheckItemDrawerDescription = ({item, children}) => {
    const {token} = useToken();

    return <>
        <Flex gap={"middle"} align={"flex-start"}>
            <ExclamationCircleFilled style={{
                fontSize: 48,
                color: item.soft ? token.colorWarning : token.colorError
            }}/>
            <div style={{maxWidth: 500}}>
                <Typography.Title level={4} style={{margin: "4px 0px 16px 0px"}}>
                    {item.ui.warningTitle}
                </Typography.Title>
                <Typography.Paragraph>
                    {item.ui.warningDescription}
                </Typography.Paragraph>
                {children}
            </div>
        </Flex>
    </>
}


const CheckItemDrawer = ({item, checks, open, setOpen}) => {
    const [ aux, {add: addAux, remove: removeAux}] = useSet();
    const {token} = useToken();

    return <MobileDrawer
        open={open && !item.passed}
        onClose={() => setOpen(false)}
    >
        <Card>
            <CheckItemDrawerDescription item={item}>
                <Typography.Paragraph>
                    {item.key === "risk_ability_not_set"
                        && <>
                            To clear this warning and get your risk ability
                            score,
                            <Button
                                onClick={() => addAux("questionnaire")}
                                type={"link"}
                                style={{padding: "0 0 0 4px"}}
                            >
                                fill the questionnaire
                            </Button>
                            .
                        </>
                    }
                    {item.key === "risk_tolerance_not_set"
                        && <>
                            To clear this warning and get your risk tolerance
                            score,
                            <Button
                                onClick={() => addAux("quiz")}
                                type={"link"}
                                style={{padding: "0 0 0 4px"}}
                            >
                                take the quiz
                            </Button>
                            .
                        </>
                    }
                    {(item.key === "high_risk_vs_ability" || item.key === "high_risk_vs_tolerance")
                        && (checks.some(check => RISK_RELATED_CHECKS.includes(check.key) && !check.passed)
                                ?
                                "High portfolio risk may be caused by the problems " +
                                "listed below. You may try to solve these problems to " +
                                "lower your portfolio risk. " +
                                "If this doesn't help, " +
                                "you may try increasing the allocation to low-risk " +
                                "assets (e.g. treasuries)."
                                :
                                "High portfolio risk may be caused by the high " +
                                "allocation to risky assets " +
                                "(e.g. equities, junk bonds, alternatives). " +
                                "To lower your portfolio risk, you may try " +
                                "increasing the allocation to low-risk " +
                                "assets (e.g. treasuries)."
                        )
                    }
                    {item.key === "large_positions"
                        && "To clear this warning, decrease the weight of the assets below."
                    }
                    {item.key === "short_positions"
                        && "To clear this warning, get rid of short positions listed below."
                    }
                    {item.key === "high_expense_ratio_funds"
                        && "To clear this warning, get rid of the funds below."
                    }
                    {item.key === "short_funds"
                        && "To clear this warning, get rid of the funds below."
                    }
                    {item.key === "leveraged_funds"
                        && "To clear this warning, get rid of the funds below."
                    }
                    {item.key === "large_cash"
                        && <>
                            For now, cash is
                            <strong>
                                {" " + ValueFormatter.oneDigitPercent(item.metric.value / 100) + " "}
                            </strong>
                            of your portfolio.
                            To clear this warning, reduce cash weight below
                            {" " + ValueFormatter.softPercent(item.metric.limit / 100) + "."}
                        </>
                    }
                    {item.key === "negative_cash"
                        && <>
                            For now, cash is
                            <strong>
                                {" " + ValueFormatter.oneDigitPercent(item.metric.value / 100) + " "}
                            </strong>
                            of your portfolio.
                            To clear this warning, make sure that cash is positive.
                        </>
                    }
                    {item.key === "bad_fundamentals"
                        && "To clear this warning, your portfolio SScore+ should be " +
                        "above 50. You may add assets with high SScore+ or " +
                        " get rid of low-score assets listed below."
                    }
                    {item.key === "bad_sector_diversification"
                        && "To clear this warning, make sure that " +
                        " sector weights in your portfolio " +
                        " do not deviate too much from " +
                        " the weights in the stock market. " +
                        " Sectors with high weight deviations are listed below."
                    }
                </Typography.Paragraph>
            </CheckItemDrawerDescription>
            <div style={{marginLeft: 64}}>
                {(item.key === "high_risk_vs_ability" || item.key === "high_risk_vs_tolerance")
                    && checks.some(check => RISK_RELATED_CHECKS.includes(check.key) && !check.passed)
                    && <div style={{maxWidth: 500}}>
                        <Typography.Title level={5}>
                            Potential causes of high portfolio risk
                        </Typography.Title>
                        <List
                            dataSource={checks.filter(check => RISK_RELATED_CHECKS.includes(check.key) && !check.passed)}
                            renderItem={(check) => {
                                return <CheckItem key={check.key} item={check}/>
                            }}
                        />
                    </div>
                }
                {item.key === "large_positions"
                    &&
                    <DetailsTable
                        data={item.details}
                        columns={["ticker", "name", "market_value", "weight"]}
                    />
                }
                {item.key === "short_positions"
                    &&
                    <DetailsTable
                        data={item.details}
                        columns={["ticker", "name", "market_value", "weight"]}
                    />
                }
                {item.key === "high_expense_ratio_funds"
                    &&
                    <DetailsTable
                        data={item.details}
                        columns={["ticker", "name", "market_value", "weight", "expense_ratio"]}
                    />

                }
                {item.key === "short_funds"
                    &&
                    <DetailsTable
                        data={item.details}
                        columns={["ticker", "name", "market_value", "weight", "short_fund"]}
                    />

                }
                {item.key === "leveraged_funds"
                    &&
                    <DetailsTable
                        data={item.details}
                        columns={["ticker", "name", "market_value", "weight", "leveraged_fund"]}
                    />

                }
                {item.key === "bad_fundamentals"
                    &&
                    <DetailsTable
                        data={item.details}
                        columns={["ticker", "name", "market_value", "weight", "sscore_plus"]}
                    />

                }
                {item.key === "bad_sector_diversification"
                    &&
                    <DetailsTable
                        data={item.details}
                        columns={["Sector", "Portfolio weight, %", "Benchmark weight, %", "Difference, p.p."]}
                        rowKey={"Sector"}
                    />

                }
            </div>
        </Card>
        {item.key === "risk_ability_not_set" &&
            <QuestionnaireDrawer
                open={aux.has("questionnaire")}
                setOpen={(yes) => yes ? addAux("questionnaire") : removeAux("questionnaire")}
            />
        }
        {item.key === "risk_tolerance_not_set" &&
            <QuizDrawer
                open={aux.has("quiz")}
                setOpen={(yes) => yes ? addAux("quiz") : removeAux("quiz")}
            />
        }

    </MobileDrawer>
}


const CheckItem = ({item, checks, id}) => {
    const [openDetails, setOpenDetails] = useState(false);
    const [hover, setHover] = useState(false);
    const {token} = useToken();
    const {sendGAEvent} = useAuth();

    return <div id={id} style={{
        borderBottom: `1px solid ${token.colorSplit}`,
        // padding: "4px 0"
    }}>
        <Flex
            gap={"small"}
            align={"baseline"}
            style={{
                width: "100%",
                background: hover ? token.colorBgHoverBlue : null,
                borderRadius: token.borderRadius,
                padding: "12px 8px",
            }}
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
        >
            <div style={{marginTop: 3}}>
                {item.passed
                    && <CheckCircleFilled style={{color: token.colorSuccess}}/>
                }
                {!item.passed && item.soft
                    &&
                    <ExclamationCircleFilled style={{color: token.colorWarning}}/>
                }
                {!item.passed && !item.soft
                    && <ExclamationCircleFilled style={{color: token.colorError}}/>
                }
            </div>
            <Flex gap={"small"} justify={"space-between"} align={"baseline"} flex={1}>
                {item.passed ? item.ui.successTitle : item.ui.warningTitle}
                {!item.passed
                    &&
                    <Flex gap={"small"}>
                        <Button
                            type={"link"} size={"small"}
                            onClick={() => {
                                setOpenDetails(true);
                                sendGAEvent({
                                    category: 'Overview',
                                    action: 'Click',
                                    label: 'Details'
                                });
                            }}
                            style={{fontSize: token.fontSizeSM}}
                            id={`${id}-details`}
                        >
                            Details
                        </Button>
                    </Flex>
                }
            </Flex>
            <CheckItemDrawer
                item={item}
                checks={checks}
                open={openDetails}
                setOpen={setOpenDetails}
            />
        </Flex>
    </div>
}


const OverviewCardTour = ({items}) => {
    const detailsItem = items.filter(item => !item.passed)[0]
    const steps = [
        {
            title: 'Review',
            description: "Here you can find a review of the key points of your portfolio",
            target: "overview-card",
        },
        {
            title: 'Warnings',
            description: "Total number of warnings",
            target: "overview-card-total-warning",
        },
        {
            title: 'Success points',
            description: "Total number of items without warnings",
            target: "overview-card-total-success-points",
        },
        {
            title: 'Checked items',
            description: "List of all checked items",
            target: "overview-card-items",
        },
        {
            title: 'Details',
            description: "Click to see warning details",
            target: `overview-card-item-${detailsItem?.key}-details`,
        },
        {
            title: 'Show all',
            description: "Click to show all/less items",
            target: "overview-card-show-all",
        },
    ];

    return <TourButton steps={steps}/>
}


const OverviewCard = ({collapsible=true}) => {
    const {isDataLocked} = useAuth();
    const {proposal} = useHolisticoProposalContext();
    const [expanded, setExpanded] = useState(false);
    const {token} = useToken();
    const {sendGAEvent} = useAuth();

    const checks = getChecks(proposal);
    const nWarning = checks.filter(check => !check.passed).length;
    const nSuccess = checks.filter(check => check.passed).length;
    let checksToShow = checks;
    if (collapsible && checks && !expanded) {
        checksToShow = checks.filter((_, i) => i < 5);
    }

    const locked = isDataLocked("#portfolio-review");

    return <Card
        hoverable
        style={{
            background: token.colorBgGrey,
            borderColor: "rgb(220, 220, 220)",
            cursor: "default",
            // position: "relative"
        }}
        id={"overview-card"}
    >
        <NestedCard background={"white"}>
            <CardHeader title="Review" icon={<AlertFilled/>} tour={<OverviewCardTour items={checksToShow}/>}/>
            <Flex gap={"large"}>
                <StatsInline
                    title={nWarning === 1 ? "warning" : "warnings"}
                    value={nWarning}
                    id={"overview-card-total-warning"}
                />
                <StatsInline
                    title={nSuccess === 1 ? "success point" : "success points"}
                    value={nSuccess}
                    id={"overview-card-total-success-points"}
                />
            </Flex>
        </NestedCard>
        <LockTooltip label={"Portfolio review"} blur locked={locked}>
            <List
                dataSource={checksToShow}
                renderItem={(item) => {
                    return <CheckItem
                        key={item.key}
                        item={item}
                        checks={checks}
                        id={`overview-card-item-${item.key}`}
                    />
                }}
                id={"overview-card-items"}
            />
            {collapsible && checks && checks.length > 5
                &&
                <Button
                    onClick={() => {
                        setExpanded(prev => !prev);
                        sendGAEvent({
                            category: 'Overview',
                            action: 'Click',
                            label: 'Show all/less'
                        });
                    }}
                    type={"link"}
                    size={"small"}
                    icon={expanded ? <UpOutlined/> : <DownOutlined/>}
                    style={{paddingLeft: 0, marginTop: token.padding}}
                    id={"overview-card-show-all"}
                >
                    {expanded ? "Show less" : "Show all"}
                </Button>
            }
        </LockTooltip>
    </Card>
}


export default OverviewCard;
