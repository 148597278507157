import React, {useState} from "react";
import {
    List,
    Typography,
    theme,
} from "antd";
import {useAuth} from "@hooks/AuthHook";
import {useAiChatContext} from "@hooks/AiChatContext";
import {RequestLockModal} from "@components/retail/cards/LockTooltip";


const {useToken} = theme;


const ChatHint = ({message, disabled=false}) => {
    const {requestLockInfo} = useAuth();
    const {sendGAEvent} = useAuth();
    const {sendMessage} = useAiChatContext();
    const [lockInfo, setLockInfo] = useState(null);
    const [openLockModal, setOpenLockModal] = useState(false);
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    const onClick = () => {
        const info = requestLockInfo("#post-ai-conversation");
        if (info.locked) {
            setLockInfo(info);
            setOpenLockModal(true);
        } else {
            sendMessage(message);
            sendGAEvent({
                category: 'AI Chat Input',
                action: 'Click',
                label: 'Send message on hint click'
            });
        }
    }

    return <>
        <List.Item
            onMouseEnter={() => setHover(true)}
            onMouseLeave={() => setHover(false)}
            onClick={() => {
                if(!disabled) {
                    onClick();
                }
            }}
            style={{
                maxWidth: "70%",
                whiteSpace: "pre-line",
                borderWidth: 1,
                borderStyle: "solid",
                borderColor: hover && !disabled ? token.colorPrimary : token.colorBorder,
                borderRadius: 24,
                padding: `${token.paddingXS}px ${token.paddingSM}px`,
                marginLeft: "auto",
                marginBottom: token.margin,
                marginRight: token.marginXS,
                width: "fit-content",
                color: hover && !disabled ? token.colorPrimary : token.colorTextDescription,
                cursor: disabled ? "not-allowed" : "pointer"
            }}
        >
            {message}
        </List.Item>
        <RequestLockModal
            limitMessage={"You exceeded the limit of using AI chat."}
            lockMessage={"AI chat is not available for your current subscription."}
            lockedUntil={lockInfo?.until}
            open={openLockModal}
            setOpen={setOpenLockModal}
        />
    </>
}


const AiChatHints = ({}) => {
    const {predefinedMessages, waitingForResponse} = useAiChatContext();
    const {token} = useToken();

    return <>
        <Typography.Text style={{
            color: token.colorTextDescription,
            fontWeight: token.fontWeightStrong,
            width: "fit-content",
            display: "block",
            marginLeft: "auto",
            marginTop: token.margin,
            marginRight: token.marginXS + 1 + token.paddingSM,
            marginBottom: token.marginXS,

        }}>
            Suggested questions
        </Typography.Text>
        <List
            gutter={24}
            size={'small'}
            dataSource={predefinedMessages}
            renderItem={(item, index) => {
                return <ChatHint key={index} message={item.message} disabled={waitingForResponse}/>
            }}
            style={{marginBottom: 120}}
        />
    </>
}


export default AiChatHints;
