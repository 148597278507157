export const RETAIL_SUBSCRIPTION_PLANS = [
    {
        id: "free",
        key: "free",
        title: "Free",
        price: 0,
        description: "For investors who start researching the market",
        features: [
            {title: "Macroeconomic dashboard with AI comments"},
            {title: "1 investment account (10 changes per month, up to 100 positions)"},
            {title: "AI chat with 10 requests per month", temp: true},
            {title: "10 AI comments to assets per month"},
            {title: "Integration with your institution", temp: true},
            {title: "Portfolio analytics with AI comments"},
            {title: "Positions analytics"},
            {title: "Risk profile"},
            {title: "Portfolio review"},
            {title: "Stress tests"},
            {title: "Retirement map", temp: true},
            {title: "Stock and ETF ratings", temp: true},
            {title: "Stock screener"},
            {title: "Models marketplace"},
        ],
    },
    {
        key: "base",
        title: "Base",
        price: 20,
        description:
            "For investors who want advanced analytical tools and AI assistance" +
            "to manage their portfolios",
        features: [
            {title: <b>Everything in Free plan</b>},
            {title: "3 investment accounts"},
            {title: "AI chat with 30 requests per month"},
            {title: "100 AI comments to assets per month"},
            {title: "Integration with your institution"},
            {title: "Retirement map"},
            {title: "Stock and ETF ratings", temp: true},
        ],
    }, {
        key: "plus",
        title: "Premium",
        price: 20,
        description:
            "Advanced features for deep portfolio analysis and extensive AI assistance",
        features: [
            {title: <b>Everything in Basic plan</b>},
            {title: "10 investment accounts"},
            {title: "AI chat with 100 requests per month"},
            {title: "Unlimited AI comments to assets"},
            {title: "Stock and ETF ratings"},
            {title: "ETF and mutual fund screener"},
        ],
    },
    {
        id: "pro",
        key: "pro",
        title: "Professional",
        price: null,
        coming_soon: true,
        description:
            "For professional advisors",
        features: [
            {title: "Macroeconomic dashboard with AI comments"},
            {
                title: "Client proposal",
                children: [
                    "AI chat",
                    "Portfolio analytics with AI comments",
                    "Risk profile",
                    "Stress tests",
                    "Retirement map",
                    "Customizable PDF reports",
                ]
            },
            {title: "Unlimited AI comments to assets"},
            {title: "Stock and fund screener"},
            {title: "Models marketplace"},
            {title: "Model portfolios"},
            {title: "Compliance risk management"},
            {title: "Teamwork tools"},
        ],
    }
];

export const RETAIL_SUBSCRIPTION_PLANS_DICT = {
    "free": RETAIL_SUBSCRIPTION_PLANS[0],
    "base": RETAIL_SUBSCRIPTION_PLANS[1],
    "plus": RETAIL_SUBSCRIPTION_PLANS[2],
    "pro": RETAIL_SUBSCRIPTION_PLANS[3],
}
