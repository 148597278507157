import axios from "axios";
import {message} from 'antd';


const axiosInstance = axios.create({
    baseURL: process.env.REACT_APP_API_ROOT,
    timeout: 300000,
    withCredentials: true
});


axiosInstance.interceptors.request.use(config => {
    config.headers["X-Referer"] = window.location.href;
    return config
})


axiosInstance.interceptors.response.use(
    response => {
        const accessDetails = response.headers.get('x-access-details');
        if (accessDetails) {
            window.sessionStorage.setItem("access-details", accessDetails);
            window.dispatchEvent(new StorageEvent("storage", {
                key: "access-details", accessDetails
            }));
        }
        return response;
    },
    error => {
        if (!error) {
            console.error("unknown error", error)
            return Promise.reject({cancel: true})
        }
        if (error.code === "ERR_CANCELED") {
            return Promise.reject({cancel: true})
        }

        if (!error.response) {
            if (error.message) {
                message.warning(error.message);
                return Promise.reject({response: error})
            }
        }
        if (error.response.status > 400) {
            if (error.response.status === 401) {
                document.location.href = document.location.href
                return
            }

            if (error.response.status === 409) {
                message.warning(error.response.data.message);
                return Promise.reject({response: null})
            }

            if (error.response.status === 403) {
                message.warning(error.response.data.message);
                return Promise.reject(error)
            }

            let data = error.response.data

            if (error.request.responseType === 'arraybuffer'
                && data.toString() === '[object ArrayBuffer]'
            ) {
                /** error response */
                data = JSON.parse(Buffer.from(data).toString('utf8'));
            }

            message.error("Server internal error: " + data.message);
        }
        return Promise.reject(error)
    }
);


export default axiosInstance;
