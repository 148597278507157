import {
    Button, ConfigProvider,
    Flex,
    FloatButton,
    Layout,
    message,
    notification,
    Result,
    Space,
    theme, Typography
} from 'antd';
import React, {useState} from "react";
import {Navigate, Route, Routes, useNavigate} from "react-router-dom";
import packageInfo from "../../../../package.json";
import HolisticoProposal from "@holistico/proposal/view/HolisticoProposal";
import HHeader from "@app/layout/ria/HHeader";
import UnderMaintenance from "@holistico/aux/UnderMaintanence";
import RecentProposals from "@holistico/proposal/recent/RecentProposals";
import Assets from "@root/site/Assets";
import Screener from "@root/site/Screener";
import ModelPortfolios from "@holistico/models/ModelPortfolios";
import {useAuth} from "@hooks/AuthHook";
import ProfileLayout from "@holistico/profile/ProfileLayout";
import Backtest from "@root/site/Backtest";
import Tutorial from "@holistico/tutorial/Tutorial";
import Supervision from "@root/site/Supervision";
import AdvisorIndexContents from "@holistico/welcome/AdvisorIndexContents";
import Notifications from "@app/layout/Notifications";
import Enrollment from "@holistico/proposal/new/Enrollment";
import {PortfolioScreener} from "@components/screener/AssetScreener";


const {Content, Footer} = Layout;
const {useToken} = theme;


const HLayout = () => {
    const version = packageInfo.version;
    const navigate = useNavigate();
    const {token} = useToken();

    const auth = useAuth();

    const ComplianceElement = () => {
        let rootElement = <Result
            status="403"
            title="403"
            subTitle="No supervisor priveleges."
            extra={<Button type="primary">Back Home</Button>}
        />
        if (auth?.isAdmin() || auth?.isSupervisor()) {
            rootElement = <Supervision/>
        }
        return rootElement
    }


    return (
        <Layout style={{
            minHeight: 750,
            background: '#f6f9fa'
        }}>
            <Notifications/>
            <HHeader/>
            <Content style={{
                backgroundImage: "none",
                background: "none",
                margin: "0 auto",
                width: "94%",
                maxWidth: 1500,
            }}>
                <Routes>
                    <Route path="" element={<Navigate to="dashboard" replace={true}/>}/>
                    <Route path="dashboard" element={<AdvisorIndexContents/>}/>
                    <Route path="proposals" element={<RecentProposals/>}/>
                    <Route path="proposals/new" element={<Enrollment/>}/>
                    <Route path="proposals/new/:id" element={<Enrollment/>}/>
                    <Route path="proposals/:id/*" element={<HolisticoProposal/>}/>
                    {/*<Route path="assets" element={<Assets/>}/>*/}
                    <Route path="screener/*" element={<Screener/>}/>
                    <Route path="profile/:id/*" element={<ProfileLayout/>}/>
                    <Route path="models" element={<ModelPortfolios/>}/>
                    <Route path="backtest/*" element={<Backtest/>}/>
                    <Route path={"compliance/*"} element={<ComplianceElement/>}/>
                    <Route path="tutorial/*" element={<Tutorial/>}/>
                    <Route path="user/report-templates" element={<UnderMaintenance/>}/>
                    <Route path="marketplace/*" element={<PortfolioScreener/>}/>
                    <Route path="*" element={
                        <Result
                            status="404"
                            title="404"
                            subTitle="Sorry, the page you visited does not exist."
                            extra={
                                <Button
                                    type="primary"
                                    onClick={() => navigate("")}
                                >
                                    Back Home
                                </Button>
                            }
                        />
                    }/>
                </Routes>
            </Content>
            <FloatButton.BackTop
                visibilityHeight={800}
                style={{
                    bottom: 16,
                    insetInlineEnd: 188
                }}
            />
            <Footer style={{
                marginTop: token.marginXL,
                // background: "rgba(0,0,0,0.03)",
                background: "none"
            }}>
                <ConfigProvider theme={{
                    token: {
                        colorLink: "rgb(130, 130, 130)",
                        linkHoverDecoration: "underline",
                    }
                }}>
                    <Flex gap={token.marginLG} justify={"space-between"} wrap={"wrap"} style={{
                        width: "100%",
                        maxWidth: 800,
                        marginLeft: "auto",
                        marginRight: "auto",
                        marginBottom: 48
                    }}>
                        <Flex vertical style={{flexGrow: 1}}>
                            <Typography.Text>
                                ©{(new Date().getFullYear())} Holistico
                                Investment
                                LLC
                            </Typography.Text>
                            <Typography.Text style={{fontSize: token.fontSizeSM, color: token.colorTextDescription}}>
                                v. {version}
                            </Typography.Text>
                        </Flex>
                        <Flex wrap={"wrap"} gap={token.marginXL} justify={"space-between"} style={{flexGrow: 1}}>
                            <Flex vertical gap={8}
                                  style={{fontSize: token.fontSizeSM}}>
                                <Typography.Text
                                    style={{fontSize: token.fontSizeSM}}>
                                    DATA PROVIDERS
                                </Typography.Text>
                                <a href={"https://intrinio.com"}
                                   target={"_blank"}>
                                    Intrinio
                                </a>
                                <a href={"https://www.morningstar.com"}
                                   target={"_blank"}>
                                    Morningstar
                                </a>
                                <a href={"https://signetfm.com"}
                                   target={"_blank"}>
                                    Signet
                                </a>
                            </Flex>
                            <Flex vertical gap={8}
                                  style={{fontSize: token.fontSizeSM}}>
                                <Typography.Text
                                    style={{fontSize: token.fontSizeSM}}>
                                    LEGAL
                                </Typography.Text>
                                <a href={"https://holisticoinvest.com/privacy-policy"}
                                   target={"_blank"}>
                                    Privacy Policy
                                </a>
                                <a href={"https://holisticoinvest.com/terms-of-service"}
                                   target={"_blank"}>
                                    Terms of Service
                                </a>
                            </Flex>
                        </Flex>
                    </Flex>
                </ConfigProvider>
            </Footer>
        </Layout>
    );
}

export default HLayout
