import React from "react";
import {Alert, Button, Flex, theme, Typography} from "antd";
import {useLocation} from "react-router-dom";
import {useAuth} from "@hooks/AuthHook";


const {useToken} = theme;


const FreeSubscriptionAlert = ({}) => {
    const {currentSubscription, accessDetails} = useAuth();
    const {token} = useToken();
    const location = useLocation();

    const subscriptionKey = accessDetails?.test_settings_enabled
        ? accessDetails?.test_settings_subscription_key
        : currentSubscription?.ui_product_key;

    if (subscriptionKey !== "free") {
        return null;
    }
    if (location.pathname?.startsWith("/individual/profile")) {
        return null;
    }

    return <Alert
        message={<>
            <Typography.Text style={{marginRight: token.marginXS}}>
                You're using free version of Holistico!
                Reveal deeper analytics
                and better AI experience with one of our paid subscriptions:
            </Typography.Text>
            <a href={"/individual/profile/subscription"}>
                <Button type={"link"} style={{padding: 0}}>
                    View subscription plans
                </Button>
            </a>
        </>}
        type={"warning"}
        // style={{width: "fit-content", marginBottom: token.margin}}
        // showIcon
        style={{
            // marginTop: token.marginSM,
            marginBottom: token.margin
        }}
    />

}

export default FreeSubscriptionAlert;
