import React, {useState} from 'react';
import {useRequest, useUpdateEffect} from 'ahooks';
import {
    Button,
    Card,
    Col,
    Divider, Empty,
    Flex,
    Row,
    Spin,
    Tabs,
    theme,
    Tooltip,
    Typography
} from "antd";
import {CheckOutlined} from "@ant-design/icons";
import {
    get_subscription_options,
    start_pay_session
} from "@API/ahooks/subscriptions";
import {
    RETAIL_SUBSCRIPTION_PLANS_DICT
} from "./subscriptionPlans";
import {getAppUrl, getLandingUrl} from "@API/utils";


const {useToken} = theme;


export const SubscriptionButton = ({
                                       priceId,
                                       current = false,
                                       landing = false,
                                       buttonText = "Subscribe",
                                       type = "primary",
                                       size = "large",
                                       style = {}
                                   }
) => {
    const {loading, run} = useRequest(start_pay_session, {
        manual: true,
        onSuccess: (result) => {
            console.log('Created the session:', result);
            window.location.href = result.data.url;
        },
        onError: (error) => {
            console.error('Error creating payment session:', error);
        },
    });

    const handleClick = () => {
        console.log(priceId);
        run(priceId);
    };

    return (landing
            ?
            <a href={`https://app.holisticoinvest.com/individual/profile/subscription?subscribe-to=${priceId}`}>
                <Button
                    type={type}
                    loading={loading}
                    disabled={current}
                    size={size}
                    style={style}
                >
                    {current ? "Current plan" : buttonText}
                </Button>
            </a>
            : <Button
                type={type}
                loading={loading}
                disabled={current}
                size={size}
                onClick={handleClick}
                style={style}
            >
                {current ? "Current plan" : buttonText}
            </Button>
    )
};


const SubscriptionItem = ({
                              id,
                              title,
                              price,
                              description,
                              features,
                              current = false,
                              recurring_interval = 'month',
                              coming_soon = false,
                              landing = false,
                              recommended = false
                          }) => {
    const {token} = useToken();

    return <Card
        hoverable
        styles={{body: {height: "100%"}}}
        style={{
            height: "100%",
            overflow: "hidden",
            cursor: "default",
            borderColor: "rgb(220, 220, 220)"
        }}
    >
        <Flex vertical justify={"space-between"} style={{height: "100%"}}>
            <Flex vertical>
                <Flex gap={"small"} align={"baseline"} wrap={"wrap"} style={{marginBottom: token.margin,}}>
                    <Typography.Text style={{
                        fontSize: token.fontSizeHeading4,
                        fontWeight: token.fontWeightStrong,
                        lineHeight: 1,
                    }}>
                        {title}
                    </Typography.Text>
                    {recommended
                        && <Typography.Text style={{
                            color: token.colorTextDescription,
                            fontSize: token.fontSizeHeading4,
                            fontWeight: 200,
                            lineHeight: 1,
                            // color: token.colorSuccess
                        }}>
                            (recommended)
                        </Typography.Text>
                    }
                </Flex>
                <Flex gap={"middle"} align={"flex-end"}>
                    {price || price === 0
                        ? <>
                            <Typography.Text style={{
                                fontSize: token.fontSizeHeading2,
                                fontWeight: token.fontWeightStrong,
                                lineHeight: 1,
                                color: token.colorStats ?? token.colorTextHeading
                            }}>
                                ${recurring_interval === "year" ? price / 12 : price}
                            </Typography.Text>
                            <Typography.Text
                                style={{
                                    marginBottom: 4,
                                    color: token.colorStats ?? token.colorTextHeading
                                }}>
                                / {recurring_interval === "year" ? "month" : recurring_interval}
                            </Typography.Text>
                        </>
                        :
                        <a
                            href={getLandingUrl() + "/advisors/sign-up-form"}
                            target={"_blank"}
                        >
                            <Typography.Text style={{
                                fontSize: token.fontSizeHeading2,
                                fontWeight: token.fontWeightStrong,
                                lineHeight: 1,
                                color: token.colorStats ?? token.colorTextHeading
                            }}>
                                Talk to us
                            </Typography.Text>
                        </a>
                    }
                </Flex>
                <Typography.Text style={{
                    color: token.colorTextDescription,
                    marginTop: token.margin,
                    minHeight: 80
                }}>
                    {description}
                </Typography.Text>
                <Divider style={{
                    marginTop: token.marginSM,
                    marginBottom: token.marginSM,
                }}/>
                <Flex vertical gap={4}>
                    {features.map((feature, idx) => {
                        return <div key={idx}>
                            <Flex gap={"small"} align={"start"}>
                                <CheckOutlined
                                    style={{color: token.colorSuccess}}/>
                                <Typography.Text style={{lineHeight: 1.2}}>
                                    {feature.title}
                                    {feature.temp
                                        && <Tooltip
                                            title={"Feature is temporary and will be limited in the future"}>
                                            <Typography.Text style={{
                                                color: token.colorError,
                                                marginLeft: token.marginXXS,
                                            }}>
                                                *
                                            </Typography.Text>
                                        </Tooltip>

                                    }
                                </Typography.Text>
                            </Flex>
                            {feature.children?.map((sub_feature, sub_idx) => {
                                return <Flex
                                    key={sub_idx}
                                    gap={"small"}
                                    align={"start"}
                                    style={{
                                        lineHeight: 1.2,
                                        marginLeft: token.marginLG
                                    }}
                                >
                                    <CheckOutlined
                                        style={{color: token.colorSuccess}}/>
                                    {sub_feature}
                                </Flex>
                            })
                            }
                        </div>
                    })}
                </Flex>
                {features.some(feature => feature.temp)
                    && <>
                        <Divider style={{
                            marginTop: token.marginSM,
                            marginBottom: token.marginSM
                        }}/>
                        <Flex gap={"small"}>
                            <Typography.Text style={{
                                color: token.colorError,
                            }}>
                                *
                            </Typography.Text>
                            <Typography.Text italic>
                                feature is temporary and will be limited in the
                                future
                            </Typography.Text>
                        </Flex>
                    </>
                }
            </Flex>
            {id === "free" && !landing
                && <div style={{height: 40, marginTop: token.marginXL}}/>
            }
            {id === "free" && landing
                && <Flex vertical gap={"small"} style={{
                    marginTop: token.marginXL,
                    width: "100%",
                    maxWidth: 400,
                    marginLeft: "auto",
                    marginRight: "auto"
                }}>
                    <a href={getAppUrl() + "/individual/"}
                       target={"_blank"}>
                        <Button type={"primary"} size={"large"} style={{
                            width: "100%",
                        }}>
                            Sign In
                        </Button>
                    </a>
                </Flex>
            }
            {id !== "free" && coming_soon
                && <Flex vertical gap={"small"} style={{
                    marginTop: token.marginXL,
                    width: "100%",
                    maxWidth: 400,
                    marginLeft: "auto",
                    marginRight: "auto"
                }}>
                    <Typography.Text
                        style={{color: token.colorTextDescription}}>
                        Online subscription coming soon!
                    </Typography.Text>
                    <a
                        href={getLandingUrl() + "/advisors/sign-up-form"}
                        target={"_blank"}
                    >
                        <Button type={"primary"} size={"large"} style={{
                            width: "100%",
                        }}>
                            Talk to us
                        </Button>
                    </a>
                </Flex>
            }
            {id !== "free" && !coming_soon
                && <SubscriptionButton
                    priceId={id}
                    current={current}
                    landing={landing}
                    style={{
                        width: "100%",
                        maxWidth: 400,
                        marginTop: token.marginXL,
                        marginLeft: "auto",
                        marginRight: "auto"
                    }}
                />
            }
        </Flex>
    </Card>
}


const IntervalPrices = ({
                            subscriptions = [],
                            interval = 'month',
                            landing = false
                        }) => {
    return (subscriptions.map((subscription) => {
        return <Col
            key={subscription.id}
            xs={24} sm={24} md={12} lg={12} xl={6}
            style={{alignSelf: "stretch"}}
        >
            <SubscriptionItem
                id={subscription.id}
                key={subscription.id}
                title={subscription.title}
                price={subscription.price}
                current={subscription.current}
                description={subscription.description}
                features={subscription.features}
                recurring_interval={subscription.recurring?.interval ?? interval}
                coming_soon={subscription.coming_soon}
                landing={landing}
                recommended={subscription.recommended}
            />
        </Col>
    }))
}

const SubscriptionOptions = ({currentPriceId, landing = false}) => {
    const [availableSubscriptions, setAvailableSubscriptions] = useState({});
    const {token} = useToken();

    const {data, error, loading} = useRequest(get_subscription_options);

    useUpdateEffect(() => {
        let productPrices = {
            'month': [RETAIL_SUBSCRIPTION_PLANS_DICT['free']],
            'year': [RETAIL_SUBSCRIPTION_PLANS_DICT['free']]
        }
        for (let price of data.data) {
            const interval = price?.recurring?.interval ?? null;
            if (!interval) {
                continue
            }
            if (!productPrices[interval]) {
                productPrices[interval] = [];
            }

            let price_product = price['product']
            let ui_product_key = price['ui_product_key']
            let ui_product_instance = RETAIL_SUBSCRIPTION_PLANS_DICT[ui_product_key]
            if (!ui_product_instance) {
                throw Error(`Product ${ui_product_key} not found.`)
            }
            ui_product_instance = {...ui_product_instance, ...price}
            ui_product_instance['title'] = price_product['name']
            ui_product_instance['description'] = price_product['description']
            ui_product_instance['current'] = currentPriceId === price['id']
            ui_product_instance['recommended'] = ui_product_key === 'plus'
            productPrices[interval].push(ui_product_instance)
        }
        productPrices['month'].push(RETAIL_SUBSCRIPTION_PLANS_DICT['pro'])
        productPrices['year'].push(RETAIL_SUBSCRIPTION_PLANS_DICT['pro'])
        setAvailableSubscriptions(productPrices)
    }, [data])

    return <Spin spinning={loading} style={{minHeight: "300px"}}>
        <Tabs
            defaultActiveKey="annual"
            centered
            size={"large"}
            items={[
                {
                    label: `Annual`,
                    key: 'year',
                    children: <Row gutter={[token.marginSM, token.marginSM]}>
                        {error
                            ? <Col span={24}>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Something went wrong while loading subscription plans"
                                />
                            </Col>
                            : <IntervalPrices
                                key={'year'}
                                subscriptions={availableSubscriptions['year']}
                                interval={"year"}
                                landing={landing}
                            />
                        }
                    </Row>,
                },
                {
                    label: `Monthly`,
                    key: 'month',
                    children: <Row gutter={[token.marginSM, token.marginSM]}>
                        {error
                            ? <Col span={24}>
                                <Empty
                                    image={Empty.PRESENTED_IMAGE_SIMPLE}
                                    description="Something went wrong while loading subscription plans"
                                />
                            </Col>
                            : <IntervalPrices
                                key={'month'}
                                subscriptions={availableSubscriptions['month']}
                                interval={"month"}
                                landing={landing}
                            />
                        }
                    </Row>,
                },
            ]}/>
    </Spin>
}

export default SubscriptionOptions;
