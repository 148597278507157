/* eslint-disable react/prop-types */
import React, {useRef, useState} from 'react';
import {
    Button,
    Col,
    Flex,
    InputNumber,
    Row,
    Tooltip,
    Typography,
    theme, Divider
} from 'antd';
import {
    CloseCircleFilled, CloseOutlined,
    SearchOutlined
} from "@ant-design/icons";
import ValueFormatter, {is_null} from "@global/ValueFormatter";
import AssetSearch from "@components/portfolio/position/new/AssetSearch";


const {useToken} = theme;


const AssetMarketValueInput = ({asset, height, onFinish, onClear}) => {
    const [marketValueInput, setMarketValueInput] = useState(asset.market_value);
    const [quantityInput, setQuantityInput] = useState(asset.quantity);
    const marketValueInputRef = useRef();
    const {token} = useToken();

    return <Flex
        gap={"small"}
        align={"center"}
        style={{
            width: "100%",
            height: height,
            background: "white",
            borderRadius: token.borderRadius - 1,
        }}
    >
        {asset.price &&
            <InputNumber
                autoFocus
                formatter={(x) => is_null(x) ? "" : ValueFormatter.int_number(x)}
                precision={0}
                step={10}
                style={{flexGrow: 2}}
                value={quantityInput}
                controls={false}
                variant={"borderless"}
                prefix={"#"}
                onChange={(value) => {
                    setQuantityInput(value);
                    setMarketValueInput(is_null(value) ? null : value * asset.price);
                }}
                onPressEnter={(e) => {
                    if (!is_null(marketValueInput)) {
                        onFinish(quantityInput, marketValueInput);
                    } else if (marketValueInputRef) {
                        marketValueInputRef.current.focus()
                    }
                }}
                placeholder={"quantity"}
            />
        }
        <InputNumber
            formatter={(x) => is_null(x) ? "" : ValueFormatter.int_number(x)}
            precision={0}
            step={1000}
            style={{flexGrow: 3}}
            value={marketValueInput}
            controls={false}
            variant={"borderless"}
            prefix={"$"}
            onChange={(value) => {
                setMarketValueInput(value);
                setQuantityInput(is_null(value) ? null : value / asset.price);
            }}
            onPressEnter={(e) => {
                if (!is_null(marketValueInput)) {
                    onFinish(quantityInput, marketValueInput);
                }
            }}
            placeholder={"mkt. value"}
            ref={marketValueInputRef}
        />
        <Tooltip title={
            is_null(marketValueInput)
                ? "Enter quantity or market value"
                : "Add asset to portfolio"
        }>
            <Button
                type={"link"}
                disabled={is_null(marketValueInput)}
                onClick={(e) => onFinish(quantityInput, marketValueInput)}
                // style={{
                //     borderLeft: "1px solid rgb(220, 220, 220)",
                //     borderRadius: 0,
                // }}
            >
                Add
            </Button>
        </Tooltip>
        <Tooltip title={"Clear search"}>
            <Button
                type={"link"}
                icon={<CloseOutlined/>}
                onClick={onClear}
            />
        </Tooltip>
    </Flex>
}


const AssetMarketValueInputRow = ({
                                      asset,
                                      tableView,
                                      onFinish,
                                      onCancel,
                                      onClear
                                  }) => {
    const {token} = useToken();
    const height = tableView ? 42 : 35;

    return <>
        {tableView &&
            <Row
                gutter={[8, 0]}
                type="flex"
                justify="start"
                align="middle"
                className="portfolio-position-row"
                style={{
                    height: height,
                    background: "#e6f7ff",
                }}
            >
                <Col span={1} onClick={onCancel} style={{textAlign: "center"}}>
                    {<SearchOutlined/>}
                </Col>
                <Col md={4} lg={4} sm={8} xs={8} onClick={onCancel}>
                    {asset?.ticker || asset?.cusip || "-"}
                </Col>
                <Col md={9} lg={9} sm={0} xs={0} onClick={onCancel}>
                    {asset?.name || "-"}
                </Col>
                <Col md={10} lg={10} sm={11} xs={11}>
                    <AssetMarketValueInput
                        asset={asset}
                        height={height}
                        onFinish={onFinish}
                        onClear={onClear}
                    />
                </Col>
            </Row>
        }
        {!tableView
            &&
            <Flex>
                {/*<Flex*/}
                {/*    justify={"center"}*/}
                {/*    align={"center"}*/}
                {/*    onClick={onCancel}*/}
                {/*    style={{minWidth: 32, maxWidth: 32}}*/}
                {/*>*/}
                {/*    {<SearchOutlined/>}*/}
                {/*</Flex>*/}
                <Flex style={{
                    flexGrow: 1,
                    border: `1px solid ${token.colorPrimary}`,
                    borderRadius: token.borderRadius,
                    background: "#e6f7ff",
                }}>
                    <Flex
                        justify={"center"}
                        align={"center"}
                        onClick={onCancel}
                        style={{minWidth: 38, maxWidth: 38}}
                    >
                        {<SearchOutlined/>}
                    </Flex>
                    <Row gutter={[16, 0]} align={"middle"}
                         style={{flexGrow: 1}}>
                        <Col xs={6} sm={6} md={6} lg={7} xl={4}
                             onClick={onCancel}>
                            <Flex align={"center"}
                                  style={{paddingLeft: 11, height: height}}>
                                {asset?.ticker || asset?.cusip || "-"}
                            </Flex>
                        </Col>
                        <Col xs={18} sm={18} md={18} lg={8} xl={8}
                             onClick={onCancel}>
                            <Flex align={"center"}
                                  style={{height: height, overflow: "hidden"}}>
                                <Typography.Text ellipsis={true}>
                                    {asset?.name || "-"}
                                </Typography.Text>
                            </Flex>
                        </Col>
                        <Col xs={24} sm={24} md={24} lg={12} xl={12}
                             style={{height: height}}>
                            <AssetMarketValueInput
                                asset={asset}
                                height={height}
                                onFinish={onFinish}
                                onClear={onClear}
                            />
                        </Col>
                    </Row>
                </Flex>
            </Flex>
        }</>
}


const PortfolioAssetSearch = ({
                                  onFinish,
                                  allowCreate = false,
                                  tableView = false,
                                  byTickerOnly = true,
                                  placeholder = "Search asset...",
                                  size = null,
                                  icon = <SearchOutlined/>,
                                  id
                              }) => {
    const [initialSearchValue, setInitialSearchValue] = useState("");
    const [newAsset, setNewAsset] = useState(null);

    return <>
        {newAsset
            ?
            <AssetMarketValueInputRow
                asset={newAsset}
                tableView={tableView}
                onFinish={(quantity, marketValue) => {
                    const newAssetExt = {
                        ...newAsset,
                        market_value: marketValue
                    };
                    if (!is_null(quantity)) {
                        newAssetExt["quantity"] = quantity;
                    }
                    onFinish(newAssetExt);
                    setInitialSearchValue("");
                    setNewAsset(null);
                }}
                onCancel={() => {
                    setInitialSearchValue(newAsset?.ticker ?? newAsset?.cusip);
                    setNewAsset(null);
                }}
                onClear={() => {
                    setInitialSearchValue("");
                    setNewAsset(null);
                }}
            />
            :
            <AssetSearch
                initialValue={initialSearchValue}
                onSelect={(asset) => {
                    if (asset?.market_value > 0) {
                        onFinish(asset);
                    } else {
                        setNewAsset(asset);
                    }
                }}
                allowCreate={allowCreate}
                tableView={tableView}
                byTickerOnly={byTickerOnly}
                placeholder={placeholder}
                size={size}
                icon={icon}
                id={id}
            />
        }
    </>
}

export default PortfolioAssetSearch;
