import React, {useState} from "react";
import {
    Flex, Image,
    theme,
    Typography
} from "antd";
import {MessageOutlined} from "@ant-design/icons";
import {useHolisticoProposalContext} from "@hooks/ProposalContext";
import AiChat from "@holistico/ai/chat/AiChat";
import MobileDrawer from "@components/retail/cards/MobileDrawer";
import {useAuth} from "@hooks/AuthHook";
import "./AiChatPanel.less"
import {useLocation} from "react-router-dom";


const {useToken} = theme;


export const HoliAvatar = ({size=null, icon=null, style={}}) => {
    const {token} = useToken();

    return <Flex justify={"center"} align={"center"} style={{
        width: size === "small" ? 24 : 30,
        height:  size === "small" ? 24 : 30,
        borderRadius: 100,
        display: "inline-flex",
        background: "linear-gradient(-45deg, #dc4ad5, #a4409c, #3F8DFA, #40c3c7)",
        backgroundSize: "400% 400%",
        animation: "gradient 8s ease infinite",
        ...style
    }}>
        {icon
            ? icon
            // : <MessageOutlined style={{
            //     color: "rgba(255, 255, 255, 0.8)",
            //     fontWeight: token.fontWeightStrong,
            //     fontSize: size === "small" ? token.fontSizeSM : token.fontSize
            // }}/>
            : <Image
                src={"/img/holi_logo_white.png"}
                preview={false}
                width={10}
                style={{
                    marginBottom: 2
                }}
            />
        }
    </Flex>
}


export const HoliFloatButton = ({onClick}) => {
    const [hover, setHover] = useState(false);
    const {token} = useToken();

    return <Flex
        onClick={onClick}
        align={"center"}
        onMouseEnter={() => setHover(true)}
        onMouseLeave={() => setHover(false)}
        style={{
            cursor: "pointer",
            bottom: 16,
            insetInlineEnd: 12,
            zIndex: 99,
            position: "fixed",
            background: hover ? "#e6f7ff" : "rgba(255, 255, 255, 0.75)",
            boxShadow: "rgba(0, 0, 0, 0.3) 0px 4px 16px 0px",
            border: "1px solid",
            borderColor: hover ? "#e6f7ff" : "rgba(220, 220, 220)",
            borderRadius: 100,
            padding: "4px 4px 4px 12px",
            backdropFilter: "blur(2px)"
        }}
    >
        <Typography.Text>
            Ask
        </Typography.Text>
        <Typography.Text strong style={{marginLeft: 4, marginRight: 12}}>
            Holi
        </Typography.Text>
        <HoliAvatar/>
    </Flex>
}


const AiChatPanel = ({aiExpand, setAiExpand}) => {
    const {proposal} = useHolisticoProposalContext();
    const portfolios = proposal?.current_portfolios;
    const {token} = useToken();
    const {sendGAEvent} = useAuth();
    const location = useLocation();

    return <>{portfolios && portfolios.length > 0
        && !location.pathname?.startsWith("/individual/profile")
        && <>
            <HoliFloatButton onClick={() => {
                setAiExpand(true);
                sendGAEvent({
                    category: 'AI Chat Pannel',
                    action: 'Click',
                    label: 'Open AI chat'
                });
            }}/>
            <MobileDrawer
                title={null}
                open={aiExpand}
                onClose={() => setAiExpand(false)}
                closable={true}
                width={640}
                style={{background: token.colorBgGreyDark}}
            >
                <div style={{
                    marginLeft: -token.marginLG,
                    marginRight: -token.marginLG,
                }}>
                    <AiChat height={"calc(100vh - 80px)"}/>
                </div>
            </MobileDrawer>
        </>
    }</>
}


export default AiChatPanel;
