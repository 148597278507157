import {App, Flex, Image, Layout, Menu, theme} from "antd";
import {
    AppstoreOutlined,
    CreditCardOutlined,
    FilterOutlined, GiftOutlined,
    LogoutOutlined, ShareAltOutlined,
    UserOutlined, WalletFilled, WalletOutlined,
} from "@ant-design/icons";
import React, {useEffect, useState} from "react";
import {NavLink, useLocation, useNavigate} from "react-router-dom";
import {useAuth} from "@hooks/AuthHook";
import {getHiddenHref} from "@global/Utils";
import Constants from "@global/Constants";
import SendToAdvisor from "@components/retail/portfolio/SendToAdvisor";


const {useToken} = theme;


const getMenuItems = (auth) => {
    let items = [
        {
            key: `/${Constants.RETAIL_URL}/dashboard`,
            label: "Dashboard",
            icon: <AppstoreOutlined/>,
        },
        {
            key: `/${Constants.RETAIL_URL}/portfolio`,
            label: "My portfolio",
            icon: <CreditCardOutlined/>,
        },{
            key: `/${Constants.RETAIL_URL}/marketplace`,
            label: "Models marketplace",
            icon: <GiftOutlined />,
        },
        {
            key: `/${Constants.RETAIL_URL}/screener`,
            label: "Screener",
            icon: <FilterOutlined/>
        },
        {
            key: `share_with_advisor`,
            label: <SendToAdvisor/>
        },
    ]

    const userMenu = [];
    userMenu.push({
            key: `/${Constants.RETAIL_URL}/profile/subscription`,
            label: "Subscription",
            icon: <WalletOutlined/>
        });
    /*if (auth.user?.roles?.includes("advisor")) {
        userMenu.push({
            key: `/${Constants.RIA_URL}`,
            label: "Advisor account",
            icon: <UserOutlined/>
        });
    }*/
    userMenu.push({
        key: "#logout",
        label: "Logout",
        icon: <LogoutOutlined/>
    });
    items.push({
        key: "#user",
        label: auth.user?.meta?.full_name,
        icon: <UserOutlined/>,
        children: userMenu
    });

    for (let item of items) {
        if (item.key !== "#user" && item.key !== "share_with_advisor") {
            item.label = getHiddenHref(item.label, item.key);
        }
        if (item.children) {
            for (let child of item.children) {
                if (child.key !== "#logout") {
                    child.label = getHiddenHref(child.label, child.key);
                }
            }
        }
    }

    return items;
}


const RetailHeader = () => {
    const [selectedKey, setSelectedKey] = useState(`/${Constants.RETAIL_URL}/portfolio`)
    const {token} = useToken();
    const location = useLocation()
    const navigate = useNavigate()
    const auth = useAuth();

    const linkFromLogo = auth.user?.roles?.includes("advisor") ? `/${Constants.RIA_URL}` : `/${Constants.RETAIL_URL}`;

    useEffect(() => {
        let path = location.pathname;
        let key = "user";
        if (path.startsWith(`/${Constants.RETAIL_URL}/dashboard`)) {
            key = `/${Constants.RETAIL_URL}/dashboard`;
        } else if (path.startsWith(`/${Constants.RETAIL_URL}/portfolio`)) {
            key = `/${Constants.RETAIL_URL}/portfolio`;
        } else if (path.startsWith(`/${Constants.RETAIL_URL}/screener`)) {
            key = `/${Constants.RETAIL_URL}/screener`;
        } else if (path.startsWith(`/${Constants.RETAIL_URL}/marketplace`)) {
            key = `/${Constants.RETAIL_URL}/marketplace`;
        } else if (path.startsWith(`/${Constants.RETAIL_URL}/user`)) {
            key = '#user';
        }
        setSelectedKey(key)
    }, [location.pathname])

    const onClick = (e) => {
        if (e.key !== 'share_with_advisor' && !e.key.includes('#')) {
            navigate(e.key);
        }
        if (e.key === "#logout") {
            auth.logout();
        }
    }

    return <App>
        <Layout.Header
            style={{
                marginBottom: token.marginMD,
                background: token.colorWhite,
                boxShadow: '0 4px 60px rgba(0,0,0,.1)',
                padding: 0
            }}
        >
            <Flex
                justify={'space-between'}
                align={'flex-start'}
                gap={16}
                style={{
                    margin: "0 auto",
                    width: "98%",
                    maxWidth: 1600,
                    paddingLeft: token.paddingLG,
                }}
            >
                <NavLink to={linkFromLogo}>
                    <Image
                        src={"/img/logo-holistico-for-white-bg.png"}
                        preview={false}
                        height={20}
                        width={137}
                    />
                </NavLink>
                <div style={{width: "calc(100% - 200px)"}}>
                    <Menu
                        items={getMenuItems(auth)}
                        selectedKeys={[selectedKey]}
                        onClick={onClick}
                        mode="horizontal"
                        style={{
                            justifyContent: 'flex-end',
                            flexGrow: 1,
                        }}
                    />
                </div>
            </Flex>
        </Layout.Header>
    </App>
}

export default RetailHeader
